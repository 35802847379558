<!--
 * @Author: your name
 * @Date: 2021-07-24 11:20:37
 * @LastEditTime: 2021-09-17 16:18:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\auth.vue
-->
<template>
  <div class="auth">
    <div class="vessel">
      <div class="content flex-h">
        <aside class="flex-v flex-vc flex-hc">
          <div
            :class="{ active: Index == 1 }"
            class="item flex-v flex-hc flex-vc"
            @click="$router.push({ name: 'userCenter' })"
          >
            <img v-if="Index == 1" src="@/assets/img/1-2.png" />
            <img v-else src="@/assets/img/1-1.png" />
            <span>个人中心</span>
          </div>
          <div
            :class="{ active: Index == 2 }"
            class="item flex-v flex-hc flex-vc"
            @click="$router.push({ name: 'apply' })"
          >
            <img v-if="Index == 2" src="@/assets/img/2-2.png" />
            <img v-else src="@/assets/img/2-1.png" />
            <span>证书申请</span>
          </div>
          <div
            :class="{ active: Index == 3 }"
            class="item flex-v flex-hc flex-vc"
            @click="$router.push({ name: 'organization' })"
          >
            <img v-if="Index == 3" src="@/assets/img/3-2.png" />
            <img v-else src="@/assets/img/3-1.png" />
            <span>我的组织</span>
          </div>
          <div
            :class="{ active: Index == 4 }"
            class="item flex-v flex-hc flex-vc"
            @click="$router.push({ name: 'sign' })"
          >
            <img v-if="Index == 4" src="@/assets/img/4-2.png" />
            <img v-else src="@/assets/img/4-1.png" />
            <span>已报名活动</span>
          </div>
          <div
            :class="{ active: Index == 5 }"
            class="item flex-v flex-hc flex-vc"
            @click="$router.push({ name: 'record' })"
          >
            <img v-if="Index == 5" src="@/assets/img/5-2.png" />
            <img v-else src="@/assets/img/5-1.png" />
            <span>活动记录</span>
          </div>
          <div
            :class="{ active: Index == 6 }"
            class="item flex-v flex-hc flex-vc"
            @click="$router.push({ name: 'prove' })"
          >
            <img v-if="Index == 6" src="@/assets/img/6-2.png" />
            <img v-else src="@/assets/img/6-1.png" />
            <span>服务证明</span>
          </div>
          <div
            :class="{ active: Index == 7 }"
            class="item flex-v flex-hc flex-vc"
            @click="$router.push({ name: 'focus' })"
          >
            <img v-if="Index == 7" src="@/assets/img/7-2.png" />
            <img v-else src="@/assets/img/7-1.png" />
            <span>我的关注</span>
          </div>
          <div
            :class="{ active: Index == 8 }"
            class="item flex-v flex-hc flex-vc"
            @click="$router.push({ name: 'account' })"
          >
            <img v-if="Index == 8" src="@/assets/img/8-2.png" />
            <img v-else src="@/assets/img/8-1.png" />
            <span>账户安全</span>
          </div>
        </aside>
        <div class="flex-1">
          <router-view></router-view>
        </div>
      </div>
    </div>
   
  </div>
</template>
<script>
export default {
  data() {
    return {
     
    };
  },
  computed: {
    Index: function () {
      // `this` 指向 vm 实例
      return this.$route.meta.Index;
    },
  },
};
</script>
<style lang="scss" scoped>
.auth {
  height: calc(100vh - 242px);
  min-height: 838px;
  background: url("../../assets/img/bg.png") center bottom;
  position: relative;
  .vessel {
    position: absolute;
    padding: 14px;
    border-radius: 20px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1000px;
    height: 712px;
    background: rgba(255, 255, 255, 0.49);
    box-sizing: border-box;
    .content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 960px;
      height: 675px;
      background: #ffffff;
      border-radius: 0 20px 20px 0;
      aside {
        width: 104px;
        background: #faf8f8;
        .item {
          cursor: pointer;
          width: 100%;
          margin: 5px 0;
          height: 67px;
          img {
            height: 27px;
          }
          span {
            margin-top: 4px;
            font-size: 16px;
            font-family: MicrosoftYaHei;
            color: #696969;
            text-align: center;
          }
          &.active {
            background: #ff6e6e;
            span {
              color: #ffffff;
            }
          }
        }
      }
    }
  }

}
</style>